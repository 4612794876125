<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>발주관리</h1></div>
    <common-area-search
        :row="2"
        :colspan="2"
        @search="getOrdCal"
        @clear="cleanSearchDataEvent"
    >
      <template v-slot:R1_C1>
        <label>상품구분</label>
        <ul>
          <kendo-dropdownlist
              ref="renewGbnRef"
              style="width: 100%"
              :data-source="$store.state.codeRequestStore.search.combobox.renewGbn.data"
              :data-text-field="'cdNm'"
              :data-value-field="'cdId'"
              :option-label="'전체'"
              @change="getOrdCal">
          </kendo-dropdownlist>
        </ul>
      </template>
      <template v-slot:R1_C2>
        <label>상품명 </label>
        <ul>
          <common-multi-drop-down
              ref="goodsNmRef"
              @change="getOrdCal"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/goods`"
              :data-value-field="'goodsCd'"
              :data-text-field="'goodsNm'"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R2_C1>
        <label>공급업체명</label>
        <ul>
          <common-multi-drop-down
              ref="supCmpyCdRef"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :params="{ searchCmpyGbn : '05', searchAllYn : 'Y' }"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
              @change="getOrdCal"
          >
          </common-multi-drop-down>
        </ul>
      </template>
    </common-area-search>
    <!-- //검색 영역 -->
    <section>
      <div class="contents_top_sec">
        <div class="c_t_button_wrap fl">
          <button @click="$router.push({ name: 'OrdManageNew' })" class="mid_btn orange mr5 ">발주등록</button>
        </div>
        <div class="c_t_button_wrap fr">
          <button @click="$router.push({ name: 'OrdManageList' })" class="mid_btn green mr5 ">목록으로보기</button>
        </div>
        <div class="state_scheduler">
          <ul>
            <li>
              <span class="state_color" style="background:rgb(252, 0, 0);"></span>발주필요
            </li>
            <li>
              <span class="state_color" style="background:rgb(255, 138, 20);"></span>입고예정
            </li>
            <li>
              <span class="state_color" style="background:rgb(75, 121, 216);"></span>입고완료
            </li>
          </ul>
        </div>
      </div>
      <div>
      <kendo-scheduler
         ref="seinRef"
         class="custom_scheculer"
         :data-source="localDataSource"
         :date="new Date()"
         :selectable="false"
         :editable="false"
         :resources="resources"
         :minor-tick-count="3"
         :event-template='`<div id="event_#: dt #_#: calendarGbn#">#: title #</div>`'
         :data-bound="calendarDataBound"
         @navigate="onUpdateSearchDate"
         :timezone="'Etc/UTC'">
        <kendo-scheduler-view
            :type="'month'"
            :events-per-day="3"
            :event-height="'auto'"
        >
        </kendo-scheduler-view>
      </kendo-scheduler>
      </div>
    </section>

    <kendo-window
        ref="setSearchWindowRef"
        :title="calendarDetailWindowTitle"
        :modal="true"
        :width="'900px'"
        style="display:none; "
    >
      <div>
        <kendo-grid
            id="grid"
            ref="gridRef"
            style="cursor: pointer; max-height:400px; min-height:80px"
            :navigatable="false"
            :reorderable="true"
            :column-menu="false"
            :resizable="true"
            :selectable="'row'"
            :data-source="calendarDetailListDataSource"
            :columns="calendarDetailGridColumns"
            @change="onChangeCalendarDetailGrid"
            :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>'}"
        >
        </kendo-grid>
      </div>
    </kendo-window>
  </div>
</template>

<script>
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
export default {
  name: 'OrdManageCalendarList',
  created () {
    const vm = this
    vm.getHoliday()
  },
  methods: {
    getHoliday : function (){
      const vm = this
      let param = { yyyy : new Date().getFullYear() }
      ApiUtil.query(`${ApiConfig.efsDomain}/tna/holiday`, param)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.holidayList = response.data.resultData
            }else {
              kendo.alert(response.data.resultStatus.messageText)
            }
            vm.$nextTick(function (){
              vm.getOrdCal()
            })
          }
      ).catch((error) => {
        // 공휴일 데이터 조회 실패해도 발주내역 목록은 조회
        vm.$nextTick(function (){
          vm.getOrdCal()
        })
        console.error(error)
      })
    },
    getOrdCal : function (){
      const vm = this
      let param = {
        searchStartYYYYMMDD: EtnersDateTimeUtil.dateToYYYYMMDD(new Date(vm.searchStartYYYYMMDD.getFullYear(), vm.searchStartYYYYMMDD.getMonth(), -14)),
        searchEndYYYYMMDD: EtnersDateTimeUtil.dateToYYYYMMDD(new Date(vm.searchStartYYYYMMDD.getFullYear(), vm.searchStartYYYYMMDD.getMonth(), 45)),
        searchCalendarGbn : [vm.constant.ordStatus.inComplete, vm.constant.ordStatus.inExpect, vm.constant.ordStatus.ordRequired],
        ...vm.searchFilterParam()
      }
      ApiUtil.query(
          `${ApiConfig.efsDomain}/efs/manage/ord/calendar`,param)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.localDataSource = []
              let displayCalGbn = [vm.constant.ordStatus.inComplete, vm.constant.ordStatus.inExpect, vm.constant.ordStatus.ordRequired]
              response.data.resultData.forEach((item, i)=>{
                if(displayCalGbn.includes(item.calendarGbn)){
                  let startDate = EtnersDateTimeUtil.lookupDateYYYYMMDD(item.dt)
                  item.start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()+1)
                  item.end = item.start
                  item.title = `${item.groupCnt}건 ${item.calendarGbnNm}`
                  vm.localDataSource.push(item)
                }
              })
            }else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    getOrdCalDeatilList : function (dt,calendarGbn){
      const vm = this
      let param = {
        searchDt : dt,
        searchCalendarGbn : calendarGbn,
        ...vm.searchFilterParam()
      }
      ApiUtil.query(
          `${ApiConfig.efsDomain}/efs/manage/ord/calendar-detail`,param)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.calendarDetailGridColumns.forEach(item=>{
                if(item.field === 'ordNo'){
                  item.hidden = !([vm.constant.ordStatus.inComplete, vm.constant.ordStatus.inExpect].includes(calendarGbn))
                }
              })
              vm.calendarDetailListDataSource = response.data.resultData
            }else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    onChangeCalendarDetailGrid : function (ev){
      const vm = this
      let selectedRow = ev.sender.select()
      let rowData = ev.sender.dataItem(selectedRow)

      if(rowData.calendarGbn === vm.constant.ordStatus.ordRequired){
        kendo.confirm('발주등록화면으로 이동하시겠습까?').done(function (){
          vm.$router.push({name:'OrdManageNew'})
          vm.$refs.setSearchWindowRef.kendoWidget().close()
        })
      }else {
        if(!!rowData.ordNo) {
          vm.$router.push({ name: 'OrdManageDetail', params: { ordNo: rowData.ordNo } })
          vm.$refs.setSearchWindowRef.kendoWidget().close()
        }
      }
    },
    // 캘린더 data에 이벤트를 걸어주는 옵션이 제공되어있지 않아 jQuery로 직접 클릭이벤트 정의
    calendarDataBound : function (e){
      const vm = this
      let scheduler = e.sender;
      let view = scheduler.view();
      let dataSourceData = scheduler.dataSource.data();

      dataSourceData.forEach((item, i)=>{
        view.table.find(`#event_${item.dt}_${item.calendarGbn}`).on("click",function (){
          vm.$refs.setSearchWindowRef.kendoWidget().center().open()
          vm.getOrdCalDeatilList(item.dt,item.calendarGbn)
          vm.calendarDetailWindowTitle = `${item.dt} ${item.calendarGbnNm} 목록`
        })
      })

      // 더보기 버튼이 생기는 경우 삭제(데이터상으로 생길일이 없지만 혹시라도 생기는경우 삭제)
      view.table.find(".k-more-events").each(function () {
        if (kendo.jQuery(this) != null) {
          kendo.jQuery(this).remove()
        }
      });

      // 공휴일 커스텀
      let slots = kendo.jQuery('.k-scheduler-content td[role=gridcell]');

      for (let i = 0; i < slots.length; i += 1) {
        let currentSlot = kendo.jQuery(slots[i]);
        let slotData = scheduler.slotByElement(currentSlot);

        let holidayItem = vm.holidayList.find((item)=>{return item.yyyymmdd === EtnersDateTimeUtil.dateToYYYYMMDD(slotData.startDate)})
        let isHoliday = !!holidayItem
        let holidayNm = isHoliday ? holidayItem.holCmt : ''

        if (isHoliday) {
          currentSlot.css('color','#d71212')
          currentSlot.find(`span[name='holiday'`).remove()
          currentSlot.append(`<span name="holiday" class="f_l">${holidayNm}</span>`)
        }
      }
    },
    onUpdateSearchDate: function (ev) {
      const vm = this
      vm.searchStartYYYYMMDD = ev.date
      vm.getOrdCal()
    },
    cleanSearchDataEvent: function (ev) {
      const refs = this.$refs

      /**
       * 상품구분, 상품명, 공급업체명
       * */
      refs.supCmpyCdRef.widget().value('')
      refs.renewGbnRef.kendoWidget().value('')
      refs.goodsNmRef.widget().value('')
      this.getOrdCal()

    },
    searchFilterParam: function () {
      let searchFilterOptions = {}
      const refs = this.$refs

      //공급업체명
      const cmpyCd = refs.supCmpyCdRef.widget().value()
      if (cmpyCd) {searchFilterOptions.searchSupCmpyCd = cmpyCd}

      // 상품구분
      const renewGbn = refs.renewGbnRef.kendoWidget().value()
      if (renewGbn) {searchFilterOptions.searchRenewGbn = renewGbn}

      // 상품명
      const goodsCd = refs.goodsNmRef.widget().value()
      if (goodsCd) {searchFilterOptions.searchGoodsCd = goodsCd}

      return searchFilterOptions ?? undefined
    },
  },
  data () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    const ordStatusCdList = [
          { ordStatus : '01', value : '01', ordStatusNm : '발주필요', color : '#FC0000' },
          { ordStatus : '03', value : '03', ordStatusNm : '입고예정', color : '#FF8A14'},
          { ordStatus : '04', value : '04', ordStatusNm : '입고완료', color : '#4B79D8'},
        ]
    return {
      ApiConfig : ApiConfig,
      holidayList : [],
      calendarDetailWindowTitle : '',
      calendarDetailListDataSource : [],
      calendarDetailGridColumns : [
        {
          field: "ordNo",
          title: "발주번호",
          width: "10%",
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          template: function (dataItem) {
            return dataItem.ordNo ?? '-'
          },
          hidden : false,
        },
        {
          field: "cmpyNm",
          title: "회사명",
          width: "15%",
          headerAttributes: headerAttributes,
        },
        {
          field: "renewGbnNm",
          title: "상품구분",
          width: "8%",
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
        },
        {
          field: "itemNm",
          title: "품목",
          width: "10%",
          headerAttributes: headerAttributes,
        },
        {
          field: "goodsNm",
          title: "상품",
          width: "15%",
          headerAttributes: headerAttributes,
        },
      ],
      searchStartYYYYMMDD: new Date(),
      searchEndYYYYMMDD: new Date(),
      constant : {
        ordStatus : {
          inComplete : '04',
          inExpect : '03',
          ordRequired : '01'
        },
        ordStatusCdList
      },
      localDataSource: [],
      resources: [
        {
          field: "calendarGbn", // The field of the Scheduler event which contains the resource identifier.
          dataSource: ordStatusCdList
        }
      ]
    }
  },
}
</script>


<style scoped>
.custom_scheculer .k-scheduler-navigation{
  flex:inherit
}
.custom_scheculer .k-more-events.k-button{
  padding:10px 0;
  background: #dddddd;
}
.custom_scheculer .k-scheduler-monthview .k-scheduler-table td{
  /* height:90px; */
}

.k-scheduler-monthview .k-scheduler-table tbody tr td:nth-of-type(1),
.k-scheduler-monthview .k-scheduler-table tbody tr th:nth-of-type(1) {
  color: #d71212;
}
.k-scheduler-monthview .k-scheduler-table tbody tr td:nth-of-type(7),
.k-scheduler-monthview .k-scheduler-table tbody tr th:nth-of-type(7) {
  color: #8095ff;
}

</style>
